import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql, PageProps } from "gatsby"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import { MarkdownRemarkProps } from "../types/types"

const Legal: React.FunctionComponent<PageProps<MarkdownRemarkProps>> = ({
  data,
}) => {
  const post = data.markdownRemark
  return (
    <div>
      <Header />
      <Container>
        <Row>
          <Col>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  )
}

export default Legal

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
